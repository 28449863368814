import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, mergeMap } from 'rxjs/operators';
import { ConnectionCheckService } from './services/connection-check.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { PlatformService, SnackService, MaintenanceService } from 'common';
import { TopbarService } from './shared/topbar/topbar.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private topbarService: TopbarService,
    private platformService: PlatformService,
    private snackSrv: SnackService,
    private connChecksSrv: ConnectionCheckService,
    private afAuth: AngularFireAuth,
    private maintenanceSrv: MaintenanceService
  ) {}

  setTitle(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData) => {
        if (routeData) {
          this.topbarService.getTitleObject().next(routeData.title || '');
          this.titleService.setTitle(`${ routeData.title || '' } - OPES JET Operator`);
        }
      });
  }

  initIntercom(): void {
    (window as any).Intercom('boot', {
      app_id: environment.intercomAppId,
      action_color: 'rgba(16, 21, 40)',
      hide_default_launcher: true
    });
    this.afAuth.user
      .subscribe(user => {
        if (user && user.uid && user.email) {
          (window as any).Intercom('update', {
            user_id: user.uid,
            email: user.email,
            name: user.displayName,
          });
        }
      });
  }

  checkAPI(): void {
    this.connChecksSrv.checkAPI()
      .pipe(first())
      .subscribe({
        error: (err) => {
          console.dir(err);
          alert(`API server connection error.`);
        }
      });
  }

  checkPlatform(): void {
    const support = this.platformService.browserSupport();
    if (!sessionStorage.getItem('platform_check')) {
      if (!support) this.snackSrv.systemError('Please note that current browser is not fully supported and Chrome is recommended instead');
      sessionStorage.setItem('platform_check', 'true');
    }
  }

  maintenanceCheck(): void {
    this.maintenanceSrv.watchMaintenance()
      .subscribe(maintenance => {
        if (maintenance.status) {
          this.router.navigate([ 'maintenance' ]);
        } else {
          if (this.router.url === '/maintenance') this.router.navigate([ '/' ]).then(() => window.location.reload());
        }
      });
  }

  ngOnInit(): void {
    this.maintenanceCheck();
    this.checkAPI();
    this.setTitle();
    this.initIntercom();
    this.checkPlatform();
  }
}
