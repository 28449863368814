const apiUrl = 'https://ops.it4biz.com.ua/api';
export const environment = {
  production: false,
  apiHealthCheckUrl: `${apiUrl}/build-info`,
  baseUrl: `${apiUrl}/operator/v1`,
  firebase: {
    apiKey: 'AIzaSyBz3ljDk_CkGvT-_nqvy_C9IBv3DjfUt50',
      authDomain: 'opesp-64474.firebaseapp.com',
    databaseURL: 'https://opesp-64474.firebaseio.com',
    projectId: 'opesp-64474',
    storageBucket: 'opesp-64474.appspot.com',
    messagingSenderId: '141305550251',
    appId: '1:141305550251:web:29f7bc87be85abc456aeee',
    measurementId: 'G-6E935C6BCZ'
  },
  intercomAppId: 'crvnddyi',
  loggerUrl: 'https://fl-dev.it4biz.com.ua:14141',
  dsn: 'https://492bd79483e74c75a4aac27f1b0a2638@o4505006114537472.ingest.sentry.io/4505035881644032'
};
